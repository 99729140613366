<template>
  <div
    class="d-flex flex-row-fluid bgi-size-cover bgi-position-center overlay-layer"
    style="background-image: url('/assets/media/bipart/bg-login.png'); margin-top: -50px;"
  >
    <div class="container">
      <div class="row p-20">
        <div class="col-lg-12">
          <h1 class="mt-10 text-white mb-6 display-4">
            {{$t("MODULEINPROGRESS.INPROGRESS")}}
          </h1>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ModuleInProgress",
  data() {
    return {
      id: this.$route.params.id,
      process_id: this.$route.params.process_id,
      phase_id: this.$route.params.phase_id
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MODULEINPROGRESS.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.$t("MODULEINPROGRESS.PROCESSO"), route: "/process-detail/" + this.process_id },
      { title: this.$t("MODULEINPROGRESS.INFO") }
    ]);
  }
};
</script>

<style scoped></style>
